import React from 'react';
import IframeResizer from 'iframe-resizer-react'
import Header from './Header';
import Footer from './Footer';

export default function Vote() {
    return (
        <div className="App">
            <Header />
            <div className="content">
                <div style={{position: 'relative', height: 80}}></div>
                <IframeResizer 
                    src="https://verify.vote.org/?partner=111111&campaign=WebbyAwards" 
                    width="100%" 
                    marginHeight="0" 
                    frameBorder="0" 
                    scrollable="no" />
            </div>
            <Footer />
        </div>
    );
} 