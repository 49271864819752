import React, {useState, useEffect} from 'react';
import Config from '../Config';
import axios from 'axios';
import {Link} from 'react-router-dom';

export default function VideoList({pieceID}) {
    const [init, setInit] = useState(false);
    const [videos, setVideos] = useState([]);

    const getStuff = async () => {
        const v = await axios.get('https://us-central1-webbyreport-811f9.cloudfunctions.net/getVideos?pieceID='+pieceID);
        
        if(v.data.length > 0) {
            setVideos(v.data);
        }

        setInit(true);
    };

    useEffect(() => {
        getStuff();
    }, []);

    return (
        <div className="video-list">
            {videos.length === 0 && init ? <div className="video-list-item">No Speech Recorded</div> : null}
        { 
            videos.map((video, v) => {
                return (
                    video.status === false ? (
                        <div key={v} className="video-list-item">Video Encoding... {video.official ? <em>Official video</em> : null}</div>
                    ) : (
                        <div key={v} className="video-list-item"><Link to={'/speech/'+video.id}>{video.official ? <>Official Speech</> : <>Team Speech</>}</Link></div>
                    ) 
                );  
            })
        }
        </div>
    );
}