import React, {useEffect, useRef, useState} from 'react';
import RecordRTC from 'recordrtc';
import Countdown from './Countdown';
import Loader from './Loader';
import axios from 'axios';
import Config from '../Config';
//import 'web-streams-polyfill';

//https://github.com/muaz-khan/RecordRTC/blob/master/simple-demos/video-recording.html
//https://github.com/imranjohn03/video-upload-s3

export default function VideoRecorder({piece = null, finalize = null, setProgress = null, official=false, goUpload}) {
    const MAX_LENGTH = 15 * 1000; // 15 seconds

    const video = useRef();
    const recorder = useRef();
    const timeout = useRef();

    const [recording, setRecording] = useState(false);
    const [recorded, setRecorded] = useState(false);
    const [noWebcam, setNoWebcam] = useState(false);
    const [countdown, setCountdown] = useState(false);
    const [uploading, setUploading] = useState(false);

    useEffect(()=> {
        go();

        return () => {
            if(recorder.current) {
                recorder.current.stream.stop();
            }
        };
    }, []);

    const go = () => {
        setProgress(2);
        //const identifyInstance = new Identify();
        let constraints = {
            audio: true, 
            video: {
                mandatory: {
                    minWidth: 600,
                    minHeight: 600,
                    maxWidth: 600,
                    maxHeight: 600
                }
            }
        };

        navigator.mediaDevices.getUserMedia(constraints)
            .then(async function(stream) {
                //console.log('setting recorder');
                recorder.current = RecordRTC(stream, {
                    type: 'video',
                    mimeType: 'video/webm',
                });
                console.log('recorder set');

                recorder.current.stream = stream;
                video.current.srcObject = stream;
                video.current.muted = true;
                video.current.volume = 0;
            })
            .catch(err => {
                //console.log(err);
                setNoWebcam(true)
            });
    }

    const retake = () => {
        setRecorded(false);
        setProgress(2);
        go();
    };

    const dataURLtoFile = (dataurl, filename) => {
        //console.log(dataurl);
        var arr = dataurl.split(',');
        //console.log(arr[0]);
        //console.log(arr[1]);
        //var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n);


        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: 'video/webm'});
    }

    const fixURL = (url) => {
        // some chrome 81 hack. geez
        const fixed = url.split('base64,')
        return 'data:video/webm;base64,' + fixed[1];
    };

    const start = async () => {
        setCountdown(true);
    };

    const record = async() => {
        setCountdown(false);
        setRecording(true);
        recorder.current.startRecording();
        timeout.current = setTimeout(() => stop(), MAX_LENGTH);
    }

    const stop = () => {
        clearTimeout(timeout.current);

        setRecording(false);
        setRecorded(true);

        video.current.src = video.current.srcObject = null;
        video.current.muted = false;
        video.current.volume = 1;

        recorder.current.stopRecording((b) => {
            //console.log("recording stopped");

            recorder.current.getDataURL((url) => {
                const fixedURL = fixURL(url);
                video.current.src = fixedURL;
                video.current.play();
            });

        });

        setProgress(3);
    };

    const save = async (o) => {
        setUploading(true);
        recorder.current.getDataURL(async (url) => {
            const fixedURL = fixURL(url);
            const r =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            
            const file = dataURLtoFile(fixedURL, r+'.webm');

            const formData = new FormData();
            formData.append('video', file);
            formData.append('official', o);
            formData.append('pieceID', piece.PieceID);

            // send with axios
            //axios.post('http://localhost:5001/experiments-29702/us-central1/saveVideo', formData, {
            
            const data = await axios.post(Config.endpoint + '/save5', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            //console.log('done', data.data.videoID);
            
            setUploading(false);
            finalize(data.data.videoID);
        });
    };

    return (
        noWebcam ? 
            <div className="flex">
                <div>
                    No webcam detected<br /><br />
                    <button onClick={goUpload}>Upload a video instead</button>
                </div>
            
            </div>
        : (
            <>
            {countdown ? <Countdown start={record} /> : null }
            {uploading ? <Loader /> : null }
            <div className="flex">
                <div className="video video-webcam">
                    <video ref={video} autoPlay playsInline></video>
                    
                    {recording ? 
                        <>
                            <div className="recording">&middot; Rec</div>
                            <button className="flipped" onClick={stop}>Stop Recording</button> 
                            <div className="countdown-progress"></div>
                        </>
                        : null }
                </div>
                <div className="video-buttons">
                    {!recording && !recorded && !countdown ? (
                        <>
                        <h1>Record your 5 word speech</h1>
                        <p>Accept your speech for {piece.PieceTitle}</p>
                        <button onClick={start}>Record Speech</button>
                        </>
                     ) : null }
                    
                    {recorded ? (
                        <>
                            <h1>Wow, that was great.</h1>
                            <button onClick={retake} className="flipped">Eh, I can do better. Retake!</button><br /><br />
                            {official ? (
                                <>
                                    <button onClick={() => save(true)}>Save my official speech</button><br /><br />
                                    <a href="#" onClick={() => save(false)}>I just want to see my personalized show</a>
                                </>
                            ) : <button onClick={() => save(false)}>See my show</button>}
                        </>
                    ) : null }
                </div>
            </div>
            </>
        )
        
    );
}