import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Browse from './browse/Browse';
import App from './App';
import Home from './home/Home';
import Speech from './browse/Speech';
import Vote from './Vote';
import GodMode from './GodMode';
import SpeechTest from './browse/SpeechTest';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function R() {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path="/browse">
                    <Browse />
                </Route>
                <Route exact path="/claire-god-mode">
                    <GodMode />
                </Route>
                <Route exact path="/browse/:term" children={<Browse />}>
                    <Browse />
                </Route>
                <Route exact path="/record-official/:EmailID" children={<App official={true} />}></Route>
                <Route exact path="/record-team/:EmailID" children={<App official={false} />}></Route>
                <Route exact path="/vote">
                    <Vote />
                </Route>
                <Route exact path="/speech/:id" children={<Speech />} />
                <Route exact path="/speech-full/:id" children={<Speech full={true} />} />

                <Route path="/">
                    <Home />
                </Route>
                <Route path="/"><div></div></Route>
            </Switch>
        </Router>
    );
}