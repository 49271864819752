import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import 'pure-react-carousel/dist/react-carousel.es.css';

ReactDOM.render(
  <Router />,
  document.getElementById('root')
);
