export default [
    {
        title: 'Show Opener',
        subtitle: 'Welcome',

        hash: 'WFH_Open_051820',
        videoID: 'open',
        presenter: null,
        winner: null,
        prefix: 'Content'
    },
    {
        title: 'Patton Oswalt',
        subtitle: 'Opening Monologue',

        hash: 'WFH_Monologue_051820',
        videoID: 'monologue',
        presenter: null,
        winner: null,
        prefix: 'Content'
    },
    {
        title: 'D-Nice',
        subtitle: 'Michelle Obama Presents to',

        hash: 'WFH_D-Nice_051820',
        videoID: 'd-nice',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Miley Cyrus',
        subtitle: 'Demi Lovato Presents to',

        hash: 'WFH_Miley_051820',
        videoID: 'miley',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Tom Hanks',
        subtitle: 'Judah Friedlander Presents to',

        hash: 'WFH_Hanks_131370_051820',
        videoID: 'hanks',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Dr. Tatiana Prowell, MD',
        subtitle: 'COVID-19 Heroes:',

        hash: 'WFH_Tatiana_051820',
        videoID: 'tatiania-prowell',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Jimmy Fallon',
        subtitle: 'Vint Cerf Presents to',

        hash: 'WFH_Fallon_123226_051820',
        videoID: 'fallon',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Avi Schiffmann',
        subtitle: 'Dr. Anthony Fauci Presents to',

        hash: 'WFH_AVI_051820',
        videoID: 'schiffmann',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Kristen Bell',
        subtitle: 'Patton Oswalt Presents to',

        hash: 'WFH_Kristen_051820',
        videoID: 'kristen',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Ellen Degeneres',
        subtitle: 'Rhett & Link Present to',

        hash: 'WFH_Ellen_051820',
        videoID: 'ellen',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'James Corden',
        subtitle: 'Patton Oswalt Presents to',

        hash: 'WFH_Corden_114748_051820',
        videoID: 'corden',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Houseparty',
        subtitle: 'Questlove Presents to',

        hash: 'WFH_Houseparty',
        videoID: 'houseparty',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'The Webby Awards',
        subtitle: 'A Message from',

        hash: 'WFH_Claire_051820_2',
        videoID: 'message',
        presenter: null,
        winner: null,
        prefix: 'Content'
    },
    {
        title: 'Swab Squad',
        subtitle: 'COVID-19 Heroes:',

        hash: 'WFH_Swab_Squad_051820',
        videoID: 'swab-squad',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Timbaland & Swizz Beatz',
        subtitle: 'Jill Scott Presents to',

        hash: 'WFH_Swizz_Timbaland_051820',
        videoID: 'timbaland-swizz',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'The Daily',
        subtitle: 'Patton Oswalt Presents to',

        hash: 'WFH_Daily_051820',
        videoID: 'the-daily',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'The Daily Show with Trevor Noah',
        subtitle: 'Isaiah Thomas Presents to',

        hash: 'WFH_Trevor_120872_051820',
        videoID: 'trevor-noah',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Black Fairy Godmother',
        subtitle: 'COVID-19 Heroes:',

        hash: 'WFH_Black_fairy_Godmother_051820',
        videoID: 'black-fairy-godmother',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },/*
    {
        title: 'Moses Sumney',
        subtitle: 'Performance',

        hash: 'moses',
        videoID: 'moses',
        presenter: null,
        winner: null,
        prefix: 'Content'
    },*/
    {
        title: 'Object & Animal / FKA twigs',
        subtitle: 'Questlove Presents to',

        hash: 'WFH_Twigs_121521_051820',
        videoID: 'fka-twigs',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Some Good News w/ John Krasinski',
        subtitle: 'Patton Oswalt Presents to',

        hash: 'WFH_Krasinski_051820',
        videoID: 'krasinski',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Invisible Hands',
        subtitle: 'COVID-19 Heroes:',

        hash: 'WFH_Invisible_hands_051820',
        videoID: 'invisible-hands',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },/*
    {
        title: 'Malala Yousafzai',
        subtitle: 'Paola Antonelli Presents to',

        hash: 'fpo',
        videoID: 'malala',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },*/
    {
        title: 'Kara Swisher & Scott Galloway',
        subtitle: 'Sarah Cooper Presents to',

        hash: 'WFH_Swisher_Gallaway_113073_051820',
        videoID: 'swisher-galloway',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Massimo Bottura',
        subtitle: 'Dave Chang Presents to',

        hash: 'WFH_Massimo_051820',
        videoID: 'massimo',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'John Hodgman',
        subtitle: 'Monica Lewinsky Presents to',

        hash: 'WFH_Hodgeman_131964_051820',
        videoID: 'hodgeman',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Shopping Angels',
        subtitle: 'COVID-19 Heroes:',

        hash: 'WFH_Shopping_Angels_051820',
        videoID: 'shopping-angels',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Google Classroom',
        subtitle: 'Desus & Mero Present to',

        hash: 'WFH_Google_Classroom_051820',
        videoID: 'google-classroom',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Oscar the Grouch',
        subtitle: 'Dan Pashman Presents to',

        hash: 'WFH_Oscar_120007_051820',
        videoID: 'oscar-the-grouch',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Deepak Chopra',
        subtitle: 'Paola Antonelli Presents to ',

        hash: 'WFH_Deepak_113573_051820',
        videoID: 'deepak',
        presenter: null,
        winner: null,
        prefix: 'Featured'
    },
    {
        title: 'Nextstrain.org',
        subtitle: 'COVID-19 Heroes:',

        hash: 'WFH_Nextstrain_051820',
        videoID: 'nextstrain',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'BBDO New York',
        subtitle: 'Patton Oswalt Presents to ',

        hash: 'WFH_BBDO_051820',
        videoID: 'bbdo',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'National Geographic',
        subtitle: 'Patton Oswalt Presents to ',

        hash: 'WFH_NatGeo_051820',
        videoID: 'natgeo',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'mssng p eces',
        subtitle: 'Patton Oswalt Presents to ',

        hash: 'WFH_Missing_Pieces_051820',
        videoID: 'mssngpeces',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'McCann Worldgroup',
        subtitle: 'Patton Oswalt Presents to ',

        hash: 'WFH_McCann_051820',
        videoID: 'mccann',
        presenter: null,
        winner: null,
        prefix: 'Special Achievement'
    },
    {
        title: 'Patton Oswalt',
        subtitle: 'Opening Closing',

        hash: 'patton-ending',
        videoID: 'closing',
        presenter: null,
        winner: null,
        prefix: 'Content'
    }
]