import React from 'react';

export default function Help() {
    return (
        <div className="form-help">
            Denise Gilley and her team are available to answer any questions right now<br />
            Email: <a href="mailto:denise@webbyawards.com" target="_blank" rel="noopener noreferrer">Denise@webbyawards.com</a><br />
            Call: + 1 212 675 3555<br />
            Text: +1 646 863 4133<br />
        </div>
    );
}