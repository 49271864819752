import React, { useCallback, Suspense, useRef, useEffect } from 'react';
import {CubeTextureLoader, sRGBEncoding}from 'three';
import { useThree, Canvas, useFrame , useLoader } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import Effects from './Effects';
import marble from '../img/marble-texture.jpg';

function Model(props) {
    const group = useRef()
    const spring = useRef();
    const { nodes } = useLoader(GLTFLoader, process.env.PUBLIC_URL + '/3d/trophy.gltf')
    const { viewport } = useThree();

    const [envMap] = useLoader(CubeTextureLoader, [
      [
        process.env.PUBLIC_URL + '/3d/wall.jpg',
        process.env.PUBLIC_URL + '/3d/wall.jpg',
        process.env.PUBLIC_URL + '/3d/wall.jpg',
        process.env.PUBLIC_URL + '/3d/wall.jpg',
        process.env.PUBLIC_URL + '/3d/wall.jpg',
        process.env.PUBLIC_URL + '/3d/wall.jpg'
      ]
      ]);

    useFrame(() => spring.current.rotation.y -= 0.01)

    const color = '#604a08';

    return (
      <group ref={group} {...props} dispose={null} scale={[50,50,50]}  position={[0, 0, 0]}>{/*rotation={[1.1,0,0]}>*/}
        <group>

          <mesh castShadow geometry={nodes.Cylinder.geometry}>
            <meshStandardMaterial attach="material" color={color} roughness={0.5} envMap={envMap} metalness={1}  />
          </mesh>
          <mesh castShadow geometry={nodes.Tube.geometry}>
            <meshStandardMaterial attach="material" color={color} roughness={0.5} envMap={envMap} metalness={1} />
          </mesh>
          <mesh castShadow geometry={nodes.Tube1.geometry}>
            <meshStandardMaterial attach="material" color={color} roughness={0.5} envMap={envMap} metalness={1}  />
          </mesh>
          <mesh castShadow geometry={nodes.Extrude_3.geometry}>
            <meshStandardMaterial attach="material" color={color} roughness={0.5} envMap={envMap} metalness={1}  />
          </mesh>
          <mesh castShadow ref={spring} castShadow geometry={nodes.Sweep.geometry}>
            <meshStandardMaterial attach="material" color={color} roughness={0.5} envMap={envMap} metalness={1}  />
          </mesh>
        </group>
      </group>
    )
  }
  

function Floor(props) {
    const ref = useRef()
    //const { viewport } = useThree()
  //<meshStandardMaterial attach="material" color="#ffffff" />

    return (
        <mesh ref={ref} rotation={[4.4, 0, 0]} position={[0, -12.3, 0]} receiveShadow>
            <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
            
            <shadowMaterial attach="material" transparent={false} opacity={0.3} />
        </mesh>
    )
}

function Light({mouse}) {
  const light = useRef()
  const { size, viewport } = useThree()
  const aspect = size.width / viewport.width

  useFrame(() => {
    light.current.position.set(mouse.current[0] / aspect, -mouse.current[1] / aspect, 0)
  });

  return (
    <pointLight ref={light} distance={5} intensity={0.5} color="#ffeac2">
        
      </pointLight>
  );
}


export default function ThreeD({play = true}) {
  const mouse = useRef([300, -200])
  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
  
    useEffect(() => {
      window.addEventListener('mousemove', onMouseMove);
    } , []);

    return (
        <div className="three-d">
            <Canvas 
              // 0, .1 1.35
              
                shadowMap 
                camera={{position: [0,0,21]}}
                invalidateFrameloop={!play}
                onCreated={({ gl }) => {
                    //gl.toneMapping = ACESFilmicToneMapping
                    gl.outputEncoding = sRGBEncoding
                  }}>
                <Suspense fallback={null}>
                    <pointLight position={[0, 0, 2]} color={'#fff'} intensity={1.5} />
                    <spotLight color={'#ccc'} intensity={1.5} position={[20, 16, 20]} angle={0.7} penumbra={1} castShadow />

                    <Floor />
                    <Model  />

                    <Light mouse={mouse}/>
                </Suspense>
            </Canvas>
        </div>
    );
}