import React, {useState, useRef, useEffect} from 'react';

export default function Countdown({start = null}) {
    const [number, setNumber] = useState(3);
    const nRef = useRef(3);

    const timer = () => {
        setTimeout(() => {
            if(nRef.current > 1) {
                nRef.current -= 1;
                setNumber(n => n - 1);
                timer();
            } else {
                start();
            }
        }, 1000);
    };

    useEffect(() => {
        timer();
    }, [])


    return (
        <div className="countdown">
            <div>
                {number}
                <div className="countdown-small">Remember...only 5 words!!</div>
            </div>
        </div>
    );
}