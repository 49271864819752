import React, {useState, useEffect} from 'react';
import ScreenHome from './ScreenHome';
import ScreenWelcome from './ScreenWelcome';
import ScreenRecorder from './ScreenRecorder';

import ScreenFinal from './ScreenFinal';

export default function Screens({official}) {
    const [section, setSection] = useState(1);
    const [videoID, setVideo] = useState(null);//'0se6BgRraukFLwcq3Sml');
    const [piece, setPiece] = useState(null);//'120574');
    const [user, setUser] = useState(null);

    const next = (n) => {
        setSection(n+1);
    };

    const login = (u) => {
        setUser(u);
        next(1);
    };

    const setPieceFunc = (p) => {
        setPiece(p);
        next(2);
    };

    const setShowFunc = (v) => {
        setVideo(v);
        setSection(4);
    };

    const goHome = () => {
        setSection(2);
        setPiece(null);
        setVideo(null);
    };

    useEffect(() => console.log(section), [section]);

    return (
        <>
            <div className="content">
                {section > 2 ? <button onClick={goHome} className="home-btn flipped">Back to Winning Work</button> : null}
                {section === 1 ? <ScreenHome section={1} currentSection={section} login={login} /> : null}
                {section === 2 ? <ScreenWelcome section={2} user={user} currentSection={section} setPiece={setPieceFunc} /> : null}
                {section === 3 ? <ScreenRecorder section={3} piece={piece} currentSection={section} setShow={setShowFunc} official={official} /> : null}
                {section === 4 ? <ScreenFinal section={4} currentSection={section} videoID={videoID} pieceID={piece.PieceID} official={official} /> : null}
            </div>
        </>
    );
}