import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Header({ color="white", section = 'main'}) {
    const [url, setURL] = useState(null);
    const [text, setText] = useState(null);
    let location = useLocation();

    useEffect(() => {
        if(location.pathname === '/browse' || location.pathname.indexOf('browse/') !== -1 || location.pathname === '/vote') {
            setURL('/');
            setText('Featured Moments')
        }
        
        if(location.pathname === '/' || location.pathname.indexOf('/speech/') !== -1 || location.pathname.indexOf('/speech-full/') !== -1) {
            setURL('/browse');
            setText('5-Word Speeches');
        }

        if(location.pathname.indexOf('/record') !== -1) {
            setURL('#');
            setText('');
        }
    }, [location]);

    return(
        <header className={'header-' + color}>
            <Link to="/" className="logo">
                <svg viewBox="0 0 1500 300" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-210.000000, -390.000000)">
                            <g id="Group" transform="translate(210.000000, 390.000000)">
                                <path d="M1343.09,0 L1343.09,102.419 L1171.02,102.419 L1171.02,0 L534.115,0 L478.471,187.5 L425.648,0 L266.374,0 L213.551,187.096 L155.083,0 L0.648,0 L0.648,79.586 C16.734,78.484 91.797,73.049 91.797,67.665 C91.797,61.657 53.45,58.163 53.45,58.163 L62.202,47.352 C62.202,47.352 118.196,51.256 118.196,68.083 C118.196,81.018 75.824,86.225 75.824,86.225 C75.824,86.225 118.591,93.435 118.591,104.736 C118.591,119.473 78,123.71 78,123.71 C78,123.71 119.788,129.831 119.788,141.383 C119.788,154.924 74.735,161.184 74.735,161.184 C74.735,161.184 120.902,168.538 120.902,178.432 C120.902,190.938 77.56,198.124 77.56,198.124 C77.56,198.124 121.46,201.573 121.46,215.078 C121.46,230.23 81.257,235.613 81.257,235.613 C81.257,235.613 89.026,236.731 97.835,238.926 C113.742,242.58 124.011,248.264 124.011,254.668 C124.011,254.713 123.992,254.757 123.992,254.801 L124.038,254.801 L124.038,300 L288.146,300 L340.567,122.984 L393.391,300 L566.373,300 L650,75.387 L650,300 L806.26,300 L806.04,188.219 L983.67,188.219 L983.89,109.274 L806.26,109.274 L806.26,79.435 L1013.7,79.435 L1013.7,300 L1171.02,300 L1171.02,188.219 L1343.09,188.219 L1343.09,300 L1499.35,299.935 L1499.35,0 L1343.09,0"></path>
                                <path d="M90.538,179.659 C90.538,172.914 19.521,169.751 0.648,168.178 L0.648,193.643 C16.078,192.558 90.538,186.756 90.538,179.659"></path>
                                <path d="M90.538,215.478 C90.538,208.624 18.003,207.758 0.648,206.717 L0.648,230.288 C15.621,229.194 90.538,222.716 90.538,215.478"></path>
                                <path d="M91.312,142.171 C91.312,135.543 20.91,134.463 0.648,131.917 L0.648,154.906 C17.78,153.853 91.312,148.967 91.312,142.171"></path>
                                <path d="M90.538,104.7 C90.538,97.888 18.799,94.452 0.648,92.835 L0.648,118.022 C20.146,116.773 90.538,111.745 90.538,104.7"></path>
                            </g>
                        </g>
                    </g>
                </svg>
            </Link>
            <div className="header-links">
                {url ? <Link to={url}>{text}</Link> : null}
                <Link to="/vote"><span><em>Vote.org:</em> Check Your<br />Voter Registration</span></Link>
            </div>
            
            
            
        </header>
    );
}