import React from 'react';
import loader from '../img/loader.gif';

export default function Loader() {
    return(
        <div className="loader">
            <div className="loader-inner">
                <img src={loader} alt="loading" />
                <span>Uploading your speech<br />Give it a minute</span>
                
            </div>
            
        </div>
    )
}