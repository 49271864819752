import React from 'react';
import VideoList from './VideoList';
import Help from './Help';

export default function ScreenWelcome({user = null, section, currentSection, setPiece}) {
    const setEntry = (e) => {
        setPiece(e);
    }

    return (
        <div className="entry-list">
            <div className="section">
                <h1 className="zanimation1">Hi, {user[0].Organization}</h1>

                <div className="zanimation2">
                    <p>THIS YEAR WE MADE AN AWARD SHOW JUST FOR YOU. CHOOSE THE WORK YOU WANT TO CELEBRATE AND WE'LL SHOW YOU WHAT WE MEAN.</p>
                </div>

                <ul className="work-list zanimation3">
                    {user.map((project, p) =>{
                        return (<li key={p}>
                                    <button onClick={()=>setEntry(project)}>
                                        <strong>{project.PieceTitle}</strong>
                                        {project.MediaType} / {project.CategoryType} / {project.Category}
                                    </button>
                                    <VideoList pieceID={project.PieceID} />
                                </li>);
                    })}
                    
                </ul>

                <Help />
            </div>
        </div>
    );
}