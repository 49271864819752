import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Config from './Config';
import { LoadingManager } from 'three';

export default function GodMode() {
    const [videos, setVideos] = useState([]);
    const get = async () => {
        const videos = await axios.get('https://us-central1-webbyreport-811f9.cloudfunctions.net/getAllVideosAdmin');
        setVideos(videos.data);
    };
    useEffect(() => get(), []);

    return (
        <>
        <table style={{width: '100%', fontFamily: 'Helvetica'}}>
            <thead>
                <tr>
                <td><strong>Piece ID</strong></td>
                <td><strong>Organization</strong></td>
                <td><strong>Speech Link</strong></td>
                <td><strong>Video Link</strong></td>
                <td><strong>Share Video Link</strong></td>
                <td><strong>Official?</strong></td>
                <td><strong>Created At</strong></td>
                </tr>
            </thead>
            <tbody>
            {
                videos.map((video, v) => {
                    let d = 'n/a';
                    if(video.createdAt) {
                        d = new Date(video.createdAt._seconds * 1000);
                        d = d.toLocaleDateString() + ' @ ' + d.getHours() + ':' + (d.getMinutes()<10?'0':'')+d.getMinutes()
                    }
                    
                    return <tr key={v}>
                    <td>{video.PieceID}</td>
                    <td>{video.Organization}</td>
                    <td><a href={"/speech/"+video.id} target="_blank">Speech Link</a></td>
                    <td><a href={Config.bucketURL + video.hash +'.mp4'} target="_blank">Video Link</a></td>
                    <td><a href={Config.bucketURL + video.hash +'-share.mp4'} target="_blank">Share Video Link</a></td>
                    <td>{video.official ? <span>Official</span> : null}</td>
                    <td>{d}</td>
                    </tr>
                })
            }
            </tbody>
        </table>
        {videos.length === 0 ? <div>Loading...</div>: null}
        </>
    );
}