import React from 'react';

export default function Progress({progress}) {

    return (
        <div className="progress">
            <div className={progress >= 1 ? "progress-cell progress-cell-on" : "progress-cell"}></div>
            <div className={progress >= 2 ? "progress-cell progress-cell-on" : "progress-cell"}></div>
            <div className={progress >= 3 ? "progress-cell progress-cell-on" : "progress-cell"}></div>
            <div className={progress >= 4 ? "progress-cell progress-cell-on" : "progress-cell"}></div>
        </div>
    );
}