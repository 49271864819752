import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Config from '../Config';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import VideoPlayer from './VideoPlayer';
import ShowVideoPlayer from '../recorder/ShowVideoPlayer';

import Twitter from '../img/twitter-white.svg';
import Facebook from '../img/facebook-white.svg';
import DownloadImg from '../img/download.svg';

export default function Speech({full = false}) {
    const [tweet, setTweet] = useState('');
    let { id } = useParams();
    const [video, setVideo] = useState(null);
    const [wide, setWide] = useState(false);
    
    const getStuff = async () => {
        let endpoint = 'https://us-central1-webbyreport-811f9.cloudfunctions.net/getVideo?videoID='+id;

        if(full === true) {
            // get full speech video
            endpoint = Config.endpoint + '/getVideoByPiece?pieceID='+id;
        }
        const v = await axios.get( endpoint);

        if(v.data) {
            setVideo(v.data);

            let org = v.data.Twitter !== '' && v.data.Twitter ? '@'+v.data.Twitter : v.data.Organization;

            let presenter = v.data.PresenterTwitter !== '' && v.data.PresenterTwitter ? '@'+v.data.PresenterTwitter : v.data.Presenter;
            let t = `BIG NEWS 🙌 ${org} won a #webby! ${presenter} presented my award, check out my #5WordSpeech #Webbys`;
            setTweet(t);
        }
    };

    const FBShare = () => {
        window.open(
            'https://www.facebook.com/sharer.php?u='+encodeURIComponent(Config.rootURL + '/speech/'+id),
            'fb share', 
            'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=600,height=600'
        );
    };

    useEffect(() => {
        getStuff();
        if(id === 'moses' || id === 'monologue' || id ==='closing') {
            setWide(true);
        }
    }, []);

    
    return(
        <>
        <Header color="black" />
        <div className={wide ? "speech-page speech-page-wide" : "speech-page"}>       
            {video ? 
                (
                    <div className="speech-page-inner">
                        {full ? 
                            <ShowVideoPlayer pieceID={video.PieceID} hash={video.hash} />
                        :
                            <VideoPlayer videoID={id} poster={Config.bucketURL + video.hash + '.jpg'} src={Config.bucketURL + video.hash + '-share.mp4'} loop={false} />
                        }
                        <div className="speech-info">
                            {video.Presenter ? (<>{video.Presenter} presents</>) : null}
                            <h2>{video.OrganizationUrl ? <a href={video.OrganizationUrl} target="_blank" rel="noopener noreferrer">{video.Organization}</a> : video.Organization}</h2>
                            {video.PieceTitle ? (<>for <h1>{video.PieceTitle}</h1></>) : null}
                            {video.MediaType ? <>{video.MediaType} /</>:null} {video.CategoryType ? <>{video.CategoryType} /</> : null} {video.Category}
                            {video.GalleryUrl ? <><a href={video.GalleryUrl} target="_blank" rel="noopener noreferrer" className="work-url">View Work</a><br /></> : null}
                                
                            

                            <div className="side-by-side">
                                <div><a className="twitter-share-button2" href={"https://twitter.com/intent/tweet?text="+encodeURIComponent(tweet)+"&via=thewebbyawards&hashtags=webby,5WordSpeech&url="+encodeURIComponent(Config.rootURL + '/speech/'+id)}><img src={Twitter} alt="Share to Twitter" /></a></div>
                                <div><a href="#" onClick={()=>FBShare()}><img src={Facebook} alt="Share to Facebook" /></a></div>
                            </div>
                        </div>
                    </div>
                )
            : <div>Loading</div>}
         </div>
        <Footer />
        </>
    );
}