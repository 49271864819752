import React, {useState, useEffect, useRef} from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import axios from 'axios';
import Identify from '../utils/Identify';

//import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Link } from 'react-router-dom';
import scrollToElement from 'scroll-to-element'
import Header from '../Header';
import Footer from '../Footer';
import ThreeD from './ThreeD';
import SectionBar from './SectionBar';
import HomeVideoPlayer from './HomeVideoPlayer';
import Config from '../Config';
import VoteModule from './VoteModule';

import ShowFlow from './ShowFlow';

import logoHome from '../img/webby-home-logo.svg';
import wpengine from '../img/wpengine-black.svg';
import monday from '../img/monday-black.svg';
import slack from '../img/slack-black.svg';
import yougov from '../img/yougov-black.svg';
import emoji from '../img/emoji.png';
import turntable from '../img/turntable.png';

import '../App.scss';

export default function Home() {
    const IdentifyInstance = new Identify();
    const ios = IdentifyInstance.mobileOS() === 'iOS';

    const [header, setHeader] = useState(false);
    const [showBar, setShowBar] = useState(false);
    const [section, setSection] = useState(0); 
    const [speeches, setSpeeches] = useState([]);
    //const [currentVideoHash, setCurrentVideoHash] = useState(null);
    //const openingRef = useRef();
    const openingVideoRef = useRef();
    const currentSection = useRef(0);

    const go = () => {
        setSection(1);
        currentSection.current = 1;
        console.log(document.querySelector('.video-stack').offsetHeight);
        scrollToElement("#section1", {
            offset: -(window.innerHeight * 0.5 - document.querySelector('#section1').offsetHeight * 0.5),
            duration: 1000
        }).on('end', () => {
            if(!ios) {
                document.querySelector('.video-trigger').click();
            }
        });
    };
    
    const stopAllVideos = () => {
        document.querySelectorAll('video').forEach(vid => vid.pause());
    };

    const setCurrentSection = (s) => {
        s = parseInt(s);
        currentSection.current = s;
        setSection(s);

    }

    const playNext = (fiveWords) => {
        console.log("PLAY NEXT... current section == " + currentSection.current);
        
        currentSection.current += 1;
        playVideo(currentSection.current, false);
    }

    const playVideo = (videoNumber, fromBar) => {
        let counter = 1;
        let found = false;
        currentSection.current = videoNumber;
        document.querySelectorAll('.video-trigger').forEach(vid => {

            if(currentSection.current === counter && !found) {
                found = true;
                
                console.log('scrolling to ' + counter);
                scrollToElement(vid.parentNode, {
                    offset: -(window.innerHeight * 0.5 - vid.parentNode.offsetHeight * 0.5),
                    duration: 1000
                }).on('end', () => {
                    if(!ios) {
                        // from the bar and from ios
                       // console.log('not going to play next');
                    //} else {
                        console.log('click next video');
                        vid.click();
                    }
                });
                setSection(currentSection.current);
                return;
            } else {
                counter++;
            }
            
        });

        if(!found) {
            console.log('done');
        }
    }

    useScrollPosition(({ prevPos, currPos }) => {
        if(currPos.y < 0) {
            setHeader(true);
        } else {
            setHeader(false);
        }

        if(currPos.y < -window.innerHeight*0.5) {
            setShowBar(true);
        } else {
            setShowBar(false);
        }
    });

    const getSpeeches = async () => {
        //const s = await axios.get(Config.endpoint + '/getAllVideos');
        //setSpeeches(s.data);
    };

    useEffect(()=> {
        let c = 1;
        document.querySelectorAll('.video-trigger').forEach(vid => {
            vid.setAttribute("data-id", c);
            c++;
        });

        if(speeches.length === 0) {
            getSpeeches();
        }
    }, []);

    return(
        <>
            {header ? <Header color="black" /> : null }

            <div className="home">
                <div className="home-header flex bg-room">
                    {window.innerWidth > 768 ? <ThreeD play={section === 0} /> : null }
                    <div className="blackout"></div>
                    {/*<div className="party-banner"><strong>Let's dance together at the Webby Afterparty</strong> 5pm PT | 8pm ET Club Quarantine Instagram Live <a href="https://instagram.com/dnice" target="_blank">@dnice</a></div>*/}
                    <div className="home-header-foreground">

                        <div className="welcome-text">Welcome to the Webbys From Home honoring the best of the Internet</div>

                        <img src={logoHome} alt="Webby's From Home" className="logo-home" />

                        <div className="home-header-buttons">
                            <div className="p">
                                <div className="fancy-text">Black sweatpants required</div><br />
                                Hosted by<br />
                                <strong className="strong-smaller">Patton Oswalt</strong><br /><br />
                                
                                With 5-Word Speeches and Appearances from<br />
                                <strong className="strong-smaller">Michelle Obama &middot; Dr. Anthony Fauci &middot; Miley Cyrus &middot; D-Nice &middot; Some Good News with John Krasinski &middot; Kristen Bell &middot; Avi Schiffmann &middot; Swizz Beatz & Timbaland &middot; Demi Lovato</strong>
                            </div>

                            <button onClick={go}>Play featured moments</button><br /><br />
                            <Link to="/browse" className="a-link"><button>BROWSE ALL 5-WORD SPEECHES </button><br /><br /></Link>
                        </div>

                        presented by:

                        <div className="footer-pre">
                            <div>
                                <a href="https://wbby.co/wfhwpengine" target="_blank" rel="noopener noreferrer"><img src={wpengine} alt="WP Engine" /></a>
                                <a href="https://monday.com/lp/webby/?utm_source=inf&utm_campaign=webby&utm_medium=website&utm_banner=wfhlogo" target="_blank" rel="noopener noreferrer"><img src={monday} alt="Monday.com" /></a>
                                <a href="https://wbby.co/wfhslack" target="_blank" rel="noopener noreferrer"><img src={slack} alt="Slack" /></a>
                                <a href="https://wbby.co/wfhyougov" target="_blank" rel="noopener noreferrer"><img src={yougov} alt="YouGov" /></a>
                            </div>
                        </div>
                    </div>

                    
                </div>

                <div ref={openingVideoRef} id="section1" className="home-section home-section-full bg-black">
                    <div className="home-video-wrap home-square-video">
                        <HomeVideoPlayer data={ShowFlow[0]} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                    </div>
                </div>

                <div className="home-section home-section-full bg-marble">
                    <div className="home-video-wrap home-video-wrap-wide">
                        <HomeVideoPlayer data={ShowFlow[1]} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                    </div>
                </div>

                <div className="home-section home-section-grid home-square-video">
                    {
                        ShowFlow.map((show, s) => {
                            if(s >= 2 && s <= 11) {
                                return <HomeVideoPlayer key={s} data={show} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                            } else {
                                return null;
                            }
                        })
                    }
                </div>

                <div className="home-section home-section-full bg-marble">
                    <div className="home-video-wrap home-video-wrap-wide">
                        <HomeVideoPlayer data={ShowFlow[12]} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                    </div>
                </div>


                <div className="home-section home-section-grid home-square-video">
                    {
                        ShowFlow.map((show, s) => {
                            if(s >= 13 && s <= 17) {
                                return <HomeVideoPlayer key={s} data={show} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                            } else {
                                return null;
                            }
                        })
                    }
                    <div className="home-section-grid-intro">
                        <div>
                            <img src={emoji} alt="Emoji" />
                        </div>
                    </div>
                </div>

                {/*<div className="home-section home-section-full bg-spotlight">
                    <div className="home-video-wrap home-video-wrap-wide">
                        <HomeVideoPlayer data={ShowFlow[18]} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                    </div>
                </div>*/}

                <div className="home-section home-section-grid home-square-video">
                    {
                        ShowFlow.map((show, s) => {
                            if(s >= 18 && s <= 28) {
                                return <HomeVideoPlayer key={s} data={show} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                            } else {
                                return null;
                            }
                        })
                    }
                    <div className="home-section-grid-intro">
                        <div>
                            <img src={turntable} alt="Turntable" />
                        </div>
                    </div>
                </div>

                <div className="home-section-grid-intro">
                    <div>
                        <h2>Companies of the year</h2>
                    </div>
                </div>

                <div className="home-section home-section-grid">
                    {
                        ShowFlow.map((show, s) => {
                            if(s >= 29 && s <= 32) {
                                return <HomeVideoPlayer key={s} data={show} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                            } else {
                                return null;
                            }
                        })
                    }
                    
                </div>

                <div className="home-section home-section-full bg-room">
                    <div className="home-video-wrap home-video-wrap-wide">
                        <HomeVideoPlayer data={ShowFlow[33]} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
                    </div>
                </div>

                <Footer />
                
                <SectionBar section={section} show={showBar} onPlayVideo={(v) => playVideo(v, true)} />
            </div>
        </>
    );
}