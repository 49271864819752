import React, {useState, useRef, useEffect} from 'react';
import { Player, ControlBar } from 'video-react';
import Config from '../Config';
import Analytics from '../Analytics';

import playImg from '../img/playImg.svg';
import pauseImg from '../img/pauseImg.svg';
import restartImg from '../img/restartImg.svg';

export default function ShowVideoPlayer({pieceID, hash}) {
    const videoIntro = useRef();
    const videoMain = useRef();
    const videoOutro = useRef();
    const [playing, setPlaying] = useState(false);
    const [finished, setFinished] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(1);
    const [playbar, setPlaybar] = useState(0);

    const video1Duration = useRef(0);
    const video2Duration = useRef(0);
    const video3Duration = useRef(0);

    const video1CurrentTime = useRef(0);
    const video2CurrentTime = useRef(0);
    const video3CurrentTime = useRef(0);

    const updateBar = () => {
        const p = Math.floor((100 / (video1Duration.current + video2Duration.current + video3Duration.current)) * (video1CurrentTime.current + video2CurrentTime.current + video3CurrentTime.current));
        setPlaybar(p);
    };

    const handleIntroChange = (state, prevState) => {
        video1Duration.current = state.duration || 0;
        video1CurrentTime.current = state.currentTime || 0;
        updateBar();
        if(state.ended === true && prevState.ended === false) {
            videoMain.current.play();
            setCurrentVideo(2);
            setPlaying(true);
        }
    };

    const handleMainChange = (state, prevState) => {
        video2Duration.current = state.duration || 0;
        video2CurrentTime.current = state.currentTime || 0;
        updateBar();
        if(state.ended === true && prevState.ended === false) {
            videoOutro.current.play();
            setCurrentVideo(3);
            setPlaying(true);
        }
    };

    const handleOutroChange = (state, prevState) => {
        video3Duration.current = state.duration || 0;
        video3CurrentTime.current = state.currentTime || 0;
        updateBar();

        if(state.ended === true && prevState.ended === false) {
            //videoOutro.current.play();
            setPlaying(false);
            setFinished(true);
        }
    };

    const play = () => {
        setPlaying(true);
        switch(currentVideo) {
            default:
            case 1:
                videoIntro.current.play();
                setCurrentVideo(1);
            break;
            case 2:
                videoMain.current.play();
                setCurrentVideo(2);
            break;
            case 3:
                videoOutro.current.play();
                setCurrentVideo(3);
            break;
        }
    };
    const pause = () => {
        setPlaying(false);
        switch(currentVideo) {
            default:
            case 1:
                videoIntro.current.pause();
                setCurrentVideo(1);
            break;
            case 2:
                videoMain.current.pause();
                setCurrentVideo(2);
            break;
            case 3:
                videoOutro.current.pause();
                setCurrentVideo(3);
            break;
        }
    };
    const restart = () => {
        setPlaying(true);
        setFinished(false);
        videoIntro.current.play();
        setCurrentVideo(1);
    };


    useEffect(() => {
        videoIntro.current.subscribeToStateChange(handleIntroChange);
        videoMain.current.subscribeToStateChange(handleMainChange);
        videoOutro.current.subscribeToStateChange(handleOutroChange);

        Analytics.sendPieceEvent('Full Show View', pieceID);
    },[]);

    return (
        <div className="video-stack">
            <Player className={currentVideo === 1 ? "video-stack-video video-current video-first" : "video-stack-video video-first"} ref={videoIntro} playsInline preload="auto" autoPlay>
                <source src={Config.bucketURL + 'long_intro/' + pieceID + '_long_intro.mp4'} />
                <ControlBar disableCompletely={true} />
            </Player>
            <Player className={currentVideo === 2 ? "video-stack-video video-current video-follow" : "video-stack-video video-follow"} ref={videoMain} playsInline preload="auto">
                {/*<source src={'/download?file=' + hash + '.mp4'} />*/}
                <source src={Config.bucketURL + hash + '.mp4'} />
                <ControlBar disableCompletely={true} />
            </Player>
            <Player className={currentVideo === 3 ? "video-stack-video video-current video-follow" : "video-stack-video video-follow"} ref={videoOutro} playsInline preload="auto">
                <source src={Config.bucketURL + 'long_outro/' + pieceID + '_long_outro.mp4'} />
                <ControlBar disableCompletely={true} />
            </Player>
            <div className="playbar">
                <div className="playbar-inner" style={{width: playbar + '%'}}></div>
            </div>
            <div className={playing ? "video-controls video-controls-playing" : "video-controls"}>
                {!playing && !finished ? <button onClick={play}>
                    <div className="video-button-inner"><img src={playImg} alt="Play" /></div>
                </button> : null }
                {playing ? <button onClick={pause}>
                    <div className="video-button-inner"><img src={pauseImg} alt="Play" /></div>
                </button> : null }
                {finished ? <button onClick={restart}>
                <div className="video-button-inner"><img src={restartImg} alt="Play" /></div>
                </button> : null }
            </div>
        </div>
    );
 
}