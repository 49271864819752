import axios from 'axios';
import Config from './Config';

const funcs = {
    sendEvent(event) { 
        window.dataLayer.push({
            'event': event
        });
    }, 
    sendPieceEvent(event, pieceID) { 
        window.dataLayer.push({
            'event': event,
            'PieceID': pieceID
        });
    }, 
    sendCreatedEvent(videoID, pieceID) {
        window.dataLayer.push({
            'event': 'Video Created',
            'VideoID': videoID,
            'PieceID': pieceID
        });
    },
    sendLogin(entrantID) { 
        window.dataLayer.push({
            'event': 'login',
            'EntrantID': entrantID
        });
    },
    videoView(videoID) { 
        window.dataLayer.push({
            'event': 'Video View',
            'Video': videoID
        });

        /*axios.post(Config.endpoint + '/videoView', {
            videoID: videoID
        }).catch(err => console.log(err));*/
    }
}

export default funcs;