import React from 'react';
import logo from './img/webby-logo.png';

import Twitter from './img/twitter.svg';
import Facebook from './img/facebook.svg';
import Instagram from './img/instagram.svg';
import Youtube from './img/youtube.svg';

import wpengine from './img/wpengine.svg';
import monday from './img/monday.svg';
import slack from './img/slack.svg';
import yougov from './img/yougov.svg';

export default function Footer() {
    return (
        <>

            <div className="footer-pre footer-pre-page">
                <div>
                    Presented By: 
                    <a href="https://wbby.co/wfhwpengine" target="_blank" rel="noopener noreferrer"><img src={wpengine} alt="WP Engine" /></a>
                    <a href="https://monday.com/lp/webby/" target="_blank" rel="noopener noreferrer"><img src={monday} alt="Monday.com" /></a>
                    <a href="https://wbby.co/wfhslack" target="_blank" rel="noopener noreferrer"><img src={slack} alt="Slack" /></a>
                    <a href="https://wbby.co/wfhyougov" target="_blank" rel="noopener noreferrer"><img src={yougov} alt="YouGov" /></a>
                </div>
            </div>
            <footer>
                <div className="footer-inner">
                    <div className="footer-left">
                        <img src={logo} alt="The Webby Awards" />
                        <div className="copyright">&copy; 2020 The Webby Awards. All rights reserved.</div>
                    </div>
                    <div className="footer-right">
                        <div className="footer-social">
                            <ul>
                            <li><a href="https://twitter.com/thewebbyawards" target="_blank" rel="noopener noreferrer"><img src={Twitter} alt="Twitter" /></a></li>
                            <li><a href="https://www.facebook.com/thewebbyawards" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a></li>
                            <li><a href="http://instagram.com/thewebbyawards" target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a></li>
                            <li><a href="http://www.youtube.com/user/TheWebbyAwards" target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="YouTube" /></a></li>
                            </ul>
                        </div>
                        <ul>
                            <li><a href="https://www.webbyawards.com/contact-us/" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                            <li><a href="http://www.iadas.net/" target="_blank" rel="noopener noreferrer">IADAS</a></li>
                            <li><a href="https://www.webbyawards.com/faq/" target="_blank" rel="noopener noreferrer">FAQ</a></li>
                            <li><a href="/terms.html" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
}