import React, {useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import Config from '../Config';
import Analytics from '../Analytics';
import Help from './Help';

import plant from '../img/plant-crop.png';

export default function ScreenHome({section, currentSection, login}) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    let { EmailID } = useParams();
    const id = useRef();

    const validate = async () => {
        if(id.current.value) {
            setLoading(true);
            const data = await axios.post('https://us-central1-webbyreport-811f9.cloudfunctions.net/login', {
                entrantID: id.current.value,
                emailID: EmailID
            });

            setLoading(false);

            if(data.data.user) {
                Analytics.sendLogin(data.data.user.EntrantID);
                login(data.data.user);
            } else {
                setError("Invalid Entrant ID");
                Analytics.sendEvent('Invalid Login');
            }
            //login(id.current.value);
        } else {
            setError("You need to enter your entrant ID");
        }
    }


    useEffect(() => Analytics.sendEvent('Recorder Login Page'), []);

    return (
        <>
        <div className="section-home flex">
            <div className="section-wide">
                <div className="section">
                    <h1>Welcome to Webbys From Home</h1>
                    HAVE YOUR FIVE WORD SPEECH? ENTER YOUR ENTRANT ID TO BEGIN.
                
                    <div className="form">
                        <input ref={id} type="text" placeholder="ENTER ID HERE" onKeyPress={() => setError(null)}/>
                        <button onClick={validate} className={loading ? "flipped button-disabled" : "flipped"}>I'M READY!</button>

                        <div className="error">{error ? error : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}}></span>}</div>

                        <Help />
                    </div>
                </div>
            </div>
        </div>
        <img src={plant} className="gold-plant" alt="Gold Plant" />
        </>
    );
}