import React, {useRef, useState} from 'react';
import Identify from '../utils/Identify';
import Help from './Help';
import Loader from './Loader';
import axios from 'axios';
import Config from '../Config';
import crop from '../img/crop-gif.gif';

export default function VideoRecorderIOS({piece = null, finalize = null, official=false}) {
    const file = useRef();
    const [uploading, setUploading] = useState(false);
    const identifyInstance = new Identify();
    const isMobile = identifyInstance.mobileOS() === "iOS" || identifyInstance.mobileOS() === "Android";
    const loadFile = async (e) => {
        
        const f = e.target.files[0];
        const fileType = e.target.files[0].type;
        const fileSize = e.target.files[0].size;
        console.log(fileType);

        if(fileType === "video/webm" || fileType === "video/mov" || fileType === "video/avi" || fileType === "video/mp4" || fileType === "video/quicktime" || fileType === "video/mpeg") {
            if(fileSize >= 20 * 1024 * 1024) {
                alert("Video files must be below 20 MB");
                return;
            }

            setUploading(true);
            const formData = new FormData();
            formData.append('video', f);
            formData.append('official', official);
            formData.append('pieceID', piece.PieceID);

            const data = await axios.post(Config.endpoint+ '/save5', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setUploading(false);
            finalize(data.data.videoID);
        } else {
            alert("You must choose a mp4, mov, webm, or avi video file.");
        }
    };

    return (
        <div className="flex">
            <div>
                <img src={crop} alt="How to crop" className="crop-gif" />
                <h1>BEFORE YOU HIT RECORD, REMEMBER:</h1>
                <ol style={{maxWidth: 500, margin: '0 auto 40px auto', textAlign: 'left'}}>
                    <li>No more than 20MB (about 3 or 4 seconds)</li>
                    <li>Light your face from the front</li>
                    <li>Limit your background noise so we can hear every word.</li>
                    <li>Center yourself in the frame.</li>
                    <li>Consider your background. No bathrooms please :)</li>
                    <li>Please leave a few seconds before and after you speak.</li>
                    <li>Remember to be yourself!</li>
                </ol>
                <label htmlFor="file" className="inputlabel">{isMobile ? <>Take {official ? <>official</> : null} Video</> : <>Choose {official ? <>official</> : null} Video</>}</label>
                <input 
                    id="file"
                    className="inputfile"
                    ref={file} 
                    type="file" 
                    accept="video/*;capture=camcorder"
                    onChange={loadFile} />
                {uploading ? <Loader /> : null }

                <Help />
            </div>
        </div>
    );
}