import React, {useState, useEffect} from 'react';
import BrowseCell from './BrowseCell';
import { Link, useHistory, useParams } from "react-router-dom";
import axios from 'axios';
import Analytics from '../Analytics';

import Config from '../Config';
import Header from '../Header';
import Footer from '../Footer';
import Rivers from './Rivers';

import tp from '../img/tp.jpg';

export default function Browse() {
    let { term } = useParams();
    let history = useHistory();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const [searchTerm, setSearchTerm] = useState(term);
    const [playlists, setPlaylists] = useState(null);
    const [results, setResults] = useState([]);
    const [searching, setSearching] = useState(false);

    const handleChange = event => {
        source.cancel();
        setSearchTerm(event.target.value);
        history.push("/browse/"+encodeURIComponent(event.target.value));
    };

    const search = async () => {
        if(searchTerm !== "" && searchTerm) {
            setSearching(true);
            try {
                const results = await axios.get('https://us-central1-webbyreport-811f9.cloudfunctions.net/search?query='+(term), {
                    cancelToken: source.token
                });
                setResults(results.data);
                setSearching(false);
            } catch(err) {
                console.log(err);
            } 
        } else {
            setResults([]);
            setSearching(false);
        }        
    };

    const getRivers = async () => {
        const results = await axios.get('https://us-central1-webbyreport-811f9.cloudfunctions.net/getPlaylists');
        setPlaylists(results.data);
    };

    useEffect(() => {
        setSearchTerm(term);
        search();
    }, [term]);

    useEffect(() => {
        if(!playlists) {
            getRivers();
        }

        Analytics.sendEvent('Browse Page');
    }, [])


    return(
        <>
            <Header section="browse" />
            <div className="browse">
                <div className="browse-header">
                    <div>
                        WEBBYS FROM HOME
                        <div className="browse-header-sub">Honoring the best of the Internet</div>

                    </div>
                </div>
                <div className="browse-searchbar">
                    <div>
                        <input type="text" placeholder={window.innerWidth < 700 ? "Search" : "Search All Speeches and Moments"} value={decodeURIComponent(searchTerm || '')} onChange={handleChange} />
                        <Link to="/browse/" className={!searchTerm ? 'clear clear-hide' : 'clear'}>Clear Search</Link>
                    </div>
                </div>

                {!searchTerm || searchTerm === "" ? (
                    <>
                    <div className="browse-grid">
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'WFH_Monologue_051820.jpg)'}}>
                            <Link to={'/speech/monologue'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Welcome</div><br />
                                    <div className="home-video-meta-title">Show Opener</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'WFH_D-Nice_051820.jpg)'}}>
                            <Link to={'/speech/d-nice'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Michelle Obama Presents to</div><br />
                                    <div className="home-video-meta-title">D-Nice</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'WFH_AVI_051820.jpg)'}}>
                            <Link to={'/speech/schiffmann'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Dr. Anthony Fauci Presents to</div><br />
                                    <div className="home-video-meta-title">Avi Schiffmann</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'WFH_Krasinski_051820.jpg)'}}>
                            <Link to={'/speech/krasinski'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Patton Oswalt Presents to</div><br />
                                    <div className="home-video-meta-title">Some Good News w/ John Krasinski</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="browse-grid">
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'WFH_Miley_051820.jpg)'}}>
                            <Link to={'/speech/miley'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Demi Lovato Presents to</div><br />
                                    <div className="home-video-meta-title">Miley Cyrus</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'WFH_Houseparty.jpg)'}}>
                            <Link to={'/speech/houseparty'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Questlove Presents to</div><br />
                                    <div className="home-video-meta-title">Houseparty</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'WFH_Swizz_Timbaland_051820.jpg)'}}>
                            <Link to={'/speech/timbaland-swizz'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Jill Scott Presents to</div><br />
                                    <div className="home-video-meta-title">Timbaland & Swizz Beatz</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'WFH_Daily_051820.jpg)'}}>
                            <Link to={'/speech/the-daily'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Patton Oswalt Presents to</div><br />
                                    <div className="home-video-meta-title">The Daily</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    </>
                ) : null}

                <div className={!searchTerm ? "browse-content browse-river" : "browse-content"}>

                    {results.map((result, r) => {
                        return <BrowseCell key={r} data={result} />;
                    })}

                    {searching && searchTerm? <div>Searching...</div> : (
                        results.length === 0 && searchTerm ? (
                            <div className="browse-nothing">
                                <div>No Results Found</div>
                                <img src={tp} alt="Toilet Paper" />
                            </div>) : null
                    )}

                    {!searchTerm || searchTerm === "" ? (
                        playlists ? <Rivers playlists={playlists} />  : <div>Loading playlists...</div>
                        
                    ) : null } 
                        
                </div>
                
            </div>
            <Footer />
            
        </>
    );
}