import React, {useState, useEffect} from 'react';
import Identify from '../utils/Identify';
//import VideoCapture from './VideoCapture/VideoCapture';
import VideoRecorder from './VideoRecorder';
import VideoRecorderIOS from './VideoRecorderIOS';
import Progress from './Progress';
import Analytics from '../Analytics';
import Help from './Help';

export default function ScreenRecorder({piece = null, section, currentSection, setShow, official}) {
    const identifyInstance = new Identify();
    const ios = identifyInstance.mobileOS() === "iOS" || identifyInstance.mobileOS() === "Android";
    const safari = identifyInstance.safari();
    const [enable, setEnable] = useState(null);

    const [progress, setProgress] = useState(1);

    const finalize = (videoID) => {
        setShow(videoID);
    };

    useEffect(() => Analytics.sendEvent('Record Page'), []);
    
    return (
        <div className="section-recorder flex">
            <div className="section-wide recorder">
                <div>
                <div className="flex">
                    {enable === null ? 
                        <div>
                            <h1>Recording speech for <div className="strong">{piece.PieceTitle}</div></h1>
                            <p><strong>HERE'S WHATS GOING TO HAPPEN:</strong></p>
                            <p>YOU'RE GOING TO RECORD YOUR 5-WORD SPEECH AND WE'RE GOING TO CREATE YOUR VERY OWN WEBBY AWARDS SHOW. THEN YOU’LL SIT BACK AND WATCH THE WORLD’S SHORTEST MOST PERSONALIZED AWARD SHOW.</p>
                            <p>FINALLY, YOU CAN DOWNLOAD A SPECIAL VERSION OF YOUR SHOW SO YOU CAN SHARE YOUR BIG MOMENT. YOUR OFFICIAL SPEECH WILL APPEAR ON OUR ONLINE GALLERY SO BE CREATIVE!</p>
                            
                            {safari ? <p>Unfortunately Safari does not support recording from your webcam. Try another browser or upload a video.</p> : null}
                            {ios || safari ? 
                                <button onClick={()=>setEnable('upload')}>Let's Do it</button> 
                                : (
                                    <div className="button-choose">
                                        <button onClick={()=>setEnable('webcam')}>Use your webcam</button>
                                        <div>or</div>
                                        <button onClick={()=>setEnable('upload')}>Upload a video</button>
                                    </div>
                                )
                            }

                            <Help />
                        </div>
                    : null }
                
                    {enable === 'upload' ? <VideoRecorderIOS piece={piece} setProgress={setProgress} finalize={finalize} official={official} /> : null}
                    {enable === 'webcam' ?  <VideoRecorder piece={piece} setProgress={setProgress} finalize={finalize} official={official} goUpload={()=>setEnable('upload')} /> : null}
                </div>
            </div>

            
            </div>
            <Progress progress={progress} />
        </div>
    );
}