import React from 'react';
import Config from '../Config';
import {Link} from 'react-router-dom';

export default function BrowseCell({data}) {
    return(
        <Link to={'/speech/'+data.id} className="browse-content-cell">
            <div className="browse-content-cell-inner">
                <div className="play-img">
                    <img src={Config.bucketURL + data.hash + '.jpg'} alt="Placeholder" />
                    <div className="play-btn"></div>
                </div>

                <div className="browse-river-meta">
                    {data.Presenter ? <div className="presenter">{data.Presenter} presents to</div> : null}
                    {data.Organization ? <div className="company-name">{data.Organization}</div> : null}
                    {/*data.PieceTitle ? <div className="work-name">for {data.PieceTitle}</div>: null*/}

                </div>
                
            </div>
            
        </Link>
    );
}